import React from "react"
import Layout from "../components/Layout"
import './Editais.scss'

const Editais = () => {
  return (
    <Layout>
    <div className="Editais container">
      <div className="editais-header">
        <h1>Editais</h1>
        <p>

        </p>
      </div>
      <div className="editais-text">
        <p>
          Em Breve.
        </p>
      </div>
    </div>
  </Layout>
  )
}

export default Editais;
